/*
$k-media-breakpoints: (
    // Small screen / phone
    sm: 576px,
    
    // Medium screen / tablet
    md: 768px,
    
    // Large screen / desktop
    lg: 1024px,
    
    // Extra large screen / wide desktop
    xl: 1200px,

    // Extra large screen / wide desktop
    xxl: 1600px
)!default;
*/
.btn-noborder {
      border: none !important;
}
.ops-static-labels {
  font-weight: 600;
}
.k-header-mobile__logo img {
  width:233px;
}
.k-header__brand img {
  width:163px;
}
.badge-data {
  text-align: left;
  font-weight: 600;
  color:gray;
}
.badge-amount {
  font-size: 2.7em;
}
hr.widget-data-divider {
  width:100%; 
  border-top:dashed 1px; 
  color:#cecece; 
  height: 20px;
}
.nav-pills.nav-tabs-btn .nav-item .nav-link {
  padding: 0.25rem 0.75rem;
}
.overview-progress {
  background-color: #8bc34a;
}
.k-widget-19 .k-widget-19__data .k-widget-19__chart .k-widget-19__bar [class*="k-widget-19__bar"] {
  background-color:#4CAF50;
}
ul.ops-new-list li {
  list-style: none;
  padding-left:0px;
}
.ops-news-item {
  display: block;
  width:100%;
}
.ops-news-item img {
  float:left;
  border-radius: 50px; 
  width: 55px; 
  margin-right: 20px;
}
.ops-thumbnail {
  border-radius: 50px; 
  width: 55px; 
  margin-right: 20px;
}
.k-widget-9 .k-widget-9__panel .k-widget-9__legends .k-widget-9__legend .k-widget-9__legend-bullet {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-top:2px;
}
.k-widget-9 .k-widget-9__panel .k-widget-9__legends .k-widget-9__legend {
  align-items: start;
  padding-right:4rem;
}

@media (min-width: 1025px) {
  .k-header {
    background-color:#232a31;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); }
    .k-header .k-header-menu .k-menu__nav > .k-menu__item > .k-menu__link {
      border-radius: 4px; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-text {
        color: #ffffff;
        font-weight: 500; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__hor-arrow {
        color: #7d7c8f; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__hor-icon {
        color: #7d7c8f; }
    .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link, .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link, .k-header .k-header-menu .k-menu__nav > .k-menu__item:hover > .k-menu__link {
      background-color: #263238; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__link-text, .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__link-text, .k-header .k-header-menu .k-menu__nav > .k-menu__item:hover > .k-menu__link .k-menu__link-text {
        color: #ffffff; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__hor-arrow, .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__hor-arrow, .k-header .k-header-menu .k-menu__nav > .k-menu__item:hover > .k-menu__link .k-menu__hor-arrow {
        color: #ffffff; }
      .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link .k-menu__hor-icon, .k-header .k-header-menu .k-menu__nav > .k-menu__item.k-menu__item--active > .k-menu__link .k-menu__hor-icon, .k-header .k-header-menu .k-menu__nav > .k-menu__item:hover > .k-menu__link .k-menu__hor-icon {
        color: #ffffff; }
    .k-header .k-header__topbar {
      padding-right: 15px; }
      .k-header .k-header__topbar .k-header__topbar-item .k-header__topbar-icon i {
        color: #ffffff; }
      .k-header .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user {
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px; }
        .k-header .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user .k-header__topbar-welcome {
          color: #f9f9fc; }
        .k-header .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user .k-header__topbar-username {
          color: #f9f9fc; }
      .k-header .k-header__topbar .k-header__topbar-item:hover .k-header__topbar-icon, .k-header .k-header__topbar .k-header__topbar-item.show .k-header__topbar-icon {
        background-color: #41bef1; }
        .k-header .k-header__topbar .k-header__topbar-item:hover .k-header__topbar-icon i, .k-header .k-header__topbar .k-header__topbar-item.show .k-header__topbar-icon i {
          color: #ffffff; }
      .k-header .k-header__topbar .k-header__topbar-item:hover.k-header__topbar-item--user, .k-header .k-header__topbar .k-header__topbar-item.show.k-header__topbar-item--user {
        background-color: #41bef1; }
        .k-header .k-header__topbar .k-header__topbar-item:hover.k-header__topbar-item--user .k-header__topbar-username, .k-header .k-header__topbar .k-header__topbar-item.show.k-header__topbar-item--user .k-header__topbar-username {
          color: #ffffff; } }

@media (max-width: 1024px) {
  .k-header-mobile {
    background-color: #232a31;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); }
    .k-header__topbar--mobile-on .k-header-mobile {
      -webkit-box-shadow: none;
      box-shadow: none; }
    .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler span {
      background: #fff; }
      .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler span::before, .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler span::after {
        background: #fff; }
    .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler:hover span {
      background: #d4ccfa; }
      .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler:hover span::before, .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler:hover span::after {
        background: #d4ccfa; }
    .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active span {
      background: #d4ccfa; }
      .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active span::before, .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active span::after {
        background: #d4ccfa; }
    .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-topbar-toggler i {
      color: #7f7d90; }
    .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-topbar-toggler.k-header-mobile__toolbar-topbar-toggler--active i, .k-header-mobile .k-header-mobile__toolbar .k-header-mobile__toolbar-topbar-toggler:hover i {
      color: #d4ccfa; }
  .k-header__topbar {
    background-color: #455a64;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .k-header__topbar .k-header__topbar-item .k-header__topbar-icon i {
      color: #ffffff; }
    .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user {
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px; }
      .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user .k-header__topbar-welcome {
        color: #a4a2ba; }
      .k-header__topbar .k-header__topbar-item.k-header__topbar-item--user .k-header__topbar-username {
        color: #ffffff; }
    .k-header__topbar .k-header__topbar-item:hover .k-header__topbar-icon, .k-header__topbar .k-header__topbar-item.show .k-header__topbar-icon {
      background-color: #26252d; }
      .k-header__topbar .k-header__topbar-item:hover .k-header__topbar-icon i, .k-header__topbar .k-header__topbar-item.show .k-header__topbar-icon i {
        color: #ffffff; }
    .k-header__topbar .k-header__topbar-item:hover.k-header__topbar-item--user, .k-header__topbar .k-header__topbar-item.show.k-header__topbar-item--user {
      background-color: #26252d; }
      .k-header__topbar .k-header__topbar-item:hover.k-header__topbar-item--user .k-header__topbar-welcome, .k-header__topbar .k-header__topbar-item.show.k-header__topbar-item--user .k-header__topbar-welcome {
        color: #a4a2ba; }
      .k-header__topbar .k-header__topbar-item:hover.k-header__topbar-item--user .k-header__topbar-username, .k-header__topbar .k-header__topbar-item.show.k-header__topbar-item--user .k-header__topbar-username {
        color: #ffffff; }
  .k-header__topbar--mobile-on .k-header__topbar {
    -webkit-box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    border-top: 1px solid #bdbdbd; } }
