/*
$k-media-breakpoints: (
    // Small screen / phone
    sm: 576px,

    // Medium screen / tablet
    md: 768px,

    // Large screen / desktop
    lg: 1024px,

    // Extra large screen / wide desktop
    xl: 1200px,

    // Extra large screen / wide desktop
    xxl: 1600px
)!default;
*/
.k-login-v1--enabled {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  background-color: #232a31;
  background-image: url(../../media/misc/bg_1.png)
}



.k-login-v1 {
  webkit-background-size: cover;
  background-size: cover;
  padding: 3rem;
}
  .k-login-v1 .k-login-v1__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
    .k-login-v1 .k-login-v1__head .k-login-v1__head-logo > a {
      display: inline-block; }
    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > h4 {
        margin-bottom: 0;
        color: #717ce6;
        font-size: 1.1rem;
        font-weight: 500; }
      .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > a {
        color: #fff;
        font-size: 1.1rem;
        margin-left: 0.5rem; }
  .k-login-v1 .k-login-v1__body {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .k-login-v1 .k-login-v1__body .k-login-v1__body-section {
      width: 50%;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info {
        width: 390px;
        margin-right: 7rem; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info > h3 {
          color: #fff;
          font-size: 1.93rem;
          font-weight: 500;
          margin-bottom: 1.5rem;
          line-height: 2; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info > p {
          color: #FFF;
          font-weight: 500;
          font-size: 1.1rem; }
    .k-login-v1 .k-login-v1__body .k-login-v1__body-seaprator {
      background-color: #eeeeee;
      height: 450px;
      width: 1px;
      margin: auto; }
    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper {
      width: 50%; }
      .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container {
        max-width: 390px;
        width: 100%;
        margin-left: 7rem; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-title {
          text-align: center;
          color: #fff;
          padding-bottom: 2rem;
          font-size: 1.8rem;
          font-weight: 500; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group .form-control {
          border-radius: 40px;
          padding: 2.4rem 2.2rem;
          margin-top: 1.3rem;
          border-color: #eeeeee;
          background-color: #fff; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::-webkit-input-placeholder {
          color: #41bcf1;
          font-weight: 500; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input:-ms-input-placeholder {
          color: #41bcf1;
          font-weight: 500; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::-ms-input-placeholder {
          color: #41bcf1;
          font-weight: 500; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::placeholder {
          color: #41bcf1;
          font-weight: 500; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input {
          color: rgb(83, 83, 83); }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action > a {
            display: inline-block; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action a.k-link {
            margin-left: 1.8rem; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action a.k-link > span {
              color: #fff; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn {
            padding: 1rem 3.5rem;
            border-radius: 60px;
            color: #fff;
            background-color: #41bcf1;
            font-size: 1.1rem; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider {
          margin: 3rem 0; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:not(:first-child):not(:last-child) {
            color: #41bcf1;
            font-weight: 500;
            font-size: 1.1rem; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:first-child {
            border-bottom: 1px solid #fff; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:last-child {
            border-bottom: 1px solid #fff; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a {
            border-radius: 60px;
            background-color: #Fff;
            font-weight: 600;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            padding: 1rem 0;
            font-size: 1rem; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a > i {
              font-size: 1.1rem;
              color: #fff; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:not(:first-child):not(:last-child) {
              margin: 0 2rem; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:hover {
              color: #fff;
              background-color: #ffffff; }
  .k-login-v1 .k-login-v1__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
      .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a {
        color: #fff;
        font-weight: 500;
        font-size: 0.93rem; }
        .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a:not(:first-child):not(:last-child) {
          margin: 0 2rem; }
    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-info > a {
      font-weight: 500;
      font-size: 1rem;
      color: #fff; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #2196F3; }

@media (max-width: 1024px) {
  .k-login-v1 {
    padding: 3rem 1rem 2rem 1rem; }
    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > h4 {
      padding-left: 1rem; }
    .k-login-v1 .k-login-v1__body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .k-login-v1 .k-login-v1__body .k-login-v1__body-section {
        padding-right: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 3rem; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info {
          width: 380px;
          max-width: 100%;
          text-align: center;
          margin-right: 0; }
      .k-login-v1 .k-login-v1__body .k-login-v1__body-seaprator {
        border: 1px solid #41bcf1;
        height: 2px;
        width: 100%;
        max-width: 500px;
        margin: 2rem auto; }
      .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper {
        padding-left: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container {
          margin-left: 0; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-title {
            padding-top: 1rem; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider {
            margin: 2rem 0; }
          .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
            margin-top: 3rem; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a > i {
              padding: 0.4rem 0.3rem 0.4rem 0; }
            .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:not(:first-child):not(:last-child) {
              margin: 0 1rem; }
    .k-login-v1 .k-login-v1__footer {
      padding-bottom: 2rem; }
      .k-login-v1 .k-login-v1__footer .k-login-v1__footer-info > a {
        padding-right: 0.5rem; }
      .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a:not(:first-child):not(:last-child) {
        padding: 0 1rem; } }

@media (max-width: 768px) {
  .k-login-v1 .k-login-v1__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .k-login-v1 .k-login-v1__head .k-login-v1__head-logo {
      -webkit-box-flex: 1;
      -ms-flex: 1 100%;
      flex: 1 100%;
      padding-left: 3rem; }
    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup {
      -webkit-box-flex: 1;
      -ms-flex: 1 100%;
      flex: 1 100%;
      text-align: right;
      padding: 3rem 0 0 3rem; }
      .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > span {
        padding-left: 0; }
  .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
    margin-bottom: 2rem; }
  .k-login-v1 .k-login-v1__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column; }
    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
      margin-bottom: 1rem; } }
